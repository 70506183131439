import dynamic from 'next/dynamic';
const Skeleton = dynamic(() => import('react-loading-skeleton'));
import SearchAutoComplete from 'components/SearchAutoComplete';

const Search = ({ handleClick, search_placeholder }) => {
    return (
        <li className="navbar__item ml-6">
            <a
                className="navbar__search"
                onClick={() => {
                    handleClick(false);
                }}
            >
                {search_placeholder && (
                    <SearchAutoComplete
                        search_placeholder={search_placeholder}
                        isMobileView={false}
                    />
                )}
                {!search_placeholder && <Skeleton width={20} height={20} />}
            </a>
        </li>
    );
};

export default Search;
